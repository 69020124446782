import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["filterForm", "selectedIdsInput"]
  static values = {gridName: String, batchActionUrl: String}

  #customFilterForm = null
  #selectedIds = []

  initialize() {
    this.submitNewCustomFilterForm = this.submitNewCustomFilterForm.bind(this)
  }

  connect() {
    this.#customFilterForm = this.element.querySelector(`form[data-form='custom-form'][data-grid='${this.gridNameValue}']`)
    if (this.#customFilterForm) {
      this.#customFilterForm.addEventListener("submit", this.submitNewCustomFilterForm)
    }
  }

  submitNewCustomFilterForm(e) {
    e.preventDefault();
    const form = e.currentTarget
    const filtersInput = form.querySelector("input[name='filters']")
    const data = new FormData(this.filterFormTarget);
    let d = {};
    for (const pair of data.entries()) {
      let key = pair[0];
      const value = pair[1];
      const regex = /([A-Za-z_]+)\[([A-Za-z_]*)\](.*)/;
      key = key.replace(regex, "$2$3");
      if (key in ["scope", "search"])
        continue
      if (key.endsWith("[]")) {
        const keyclean = key.substring(0, key.length - 2);
        if (!d[keyclean])
          d[keyclean] = [];
        d[keyclean].push(value);
      } else {
        d[key] = value;
      }
    }
    const json = JSON.stringify(d);
    const hiddenInput = form.querySelector("input[name='filters']")
    hiddenInput.value = json
    form.submit();
  }

  // Grouped Actions

  toggleSelectedId(event) {
    const id = event.currentTarget.dataset.id
    if (event.currentTarget.checked) {
      this.#selectedIds.push(id);
    } else {
      this.#selectedIds = this.#selectedIds.filter(i => i !== id);
    }
  }

  async callGroupedAction(event) {
    event.preventDefault()
    event.stopPropagation()

    if (confirm("Confirmer l'action ?")) {
      name = event.currentTarget.dataset.name
      fetch(this.batchActionUrlValue, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
        },
        body: JSON.stringify({
          batch_action: name,
          selected_ids: this.#selectedIds,
        })
      })
        .then(response => {
          if (response.redirected) {
            Turbo.visit(response.url);
          } else {
            return response.text();
          }
        })
    }
  }
}
