import Dropdown from "stimulus-dropdown";
import {useClickOutside} from "stimulus-use";

export default class extends Dropdown {
  static targets = ["list", "toggler"];

  initialize() {
    this.onScroll = this.onScroll.bind(this)
  }

  connect() {
    super.connect();
    this.element[this.identifier] = this;
    useClickOutside(this, {element: this.menuTarget});
  }

  hide(event) {
    if (event)
      super.hide(event);
    this.element.classList.remove("open");
    this.menuTarget.classList.add("hidden");
    this.element.dispatchEvent(new CustomEvent("close"))
    window.removeEventListener("scroll", this.onScroll, true);
  }

  toggle(event) {
    if (this.menuTarget.classList.contains("hidden")) {
      this.show()
    } else {
      this.hide(event)
    }
  }

  show(event) {
    this.element.classList.add("open");
    this.menuTarget.classList.remove("hidden");
    this.updatePosition();
    window.addEventListener("scroll", this.onScroll, true);
  }

  onScroll(event) {
    try {
      if (event?.target && event.target.closest('.oq-dropdown')) {
        // Prevent repositioning if scrolling inside dropdown
        return false;
      }
    } catch (e) {
    }
    this.updatePosition()
  }

  isFixedRelativeToContainer(element) {
    let currentNode = element.parentElement;

    while (currentNode && currentNode !== document.documentElement) {
      const computedStyle = window.getComputedStyle(currentNode);

      // Check for properties that create a new containing block
      if (
        computedStyle.transform !== 'none' ||
        computedStyle.willChange.includes('transform') ||
        computedStyle.filter !== 'none' ||
        computedStyle.perspective !== 'none' ||
        computedStyle.contain !== 'none'
      ) {
        return currentNode; // Return the container that affects positioning
      }

      currentNode = currentNode.parentElement;
    }

    return null; // Returns null if the element is relative to the viewport
  }

  updatePosition() {
    // Only update position when open
    if (!this.element.classList.contains("open"))
      return false

    const container = this.isFixedRelativeToContainer(this.menuTarget)

    const padding = 8
    this.menuTarget.style.removeProperty('top');
    this.menuTarget.style.removeProperty('right');
    this.menuTarget.style.removeProperty('bottom');
    this.menuTarget.style.removeProperty('left');

    let offsetX = 0
    let offsetY = 0
    const {top, left, right, bottom, height} = this.togglerTarget.getBoundingClientRect()
    if (container) {
      const containerRect = container.getBoundingClientRect()
      offsetX = containerRect.left * -1
      offsetY = containerRect.top * -1
    }

    const parentScrollTop = this.element.scrollTop;

    const w = window.innerWidth;
    const h = document.body.offsetHeight;

    let menuRect = this.menuTarget.getBoundingClientRect();

    // Calculate dropdown position based on the parent scroll position
    let menuTop = top + height - parentScrollTop + padding + offsetY
    // let menuTop = top + height + window.scrollY - parentScrollTop + padding + offsetY
    let menuLeft = left + window.scrollX + offsetX
    let menuRight = w - right
    let menuTopBottom = top - menuRect.height - padding
    this.menuTarget.style.maxHeight = ''

    this.menuTarget.style.top = `${menuTop}px`;
    this.menuTarget.style.left = `${menuLeft}px`;

    menuRect = this.menuTarget.getBoundingClientRect();
    if (w < menuRect.right) {
      this.menuTarget.style.right = `${menuRight}px`
      this.menuTarget.style.removeProperty('left');
    }
    if (h < menuRect.bottom) {
      // Check available space on top
      if (menuRect.height > (top + window.scrollY - padding)) {
        this.menuTarget.style.maxHeight = `${top + window.scrollY - padding}px`
        menuTopBottom = top - (top + window.scrollY - padding) - padding
      }
      this.menuTarget.style.top = `${menuTopBottom}px`
    }
  }

  clickOutside(e) {
    e.preventDefault()

    let rect = this.menuTarget.getBoundingClientRect();
    if (!(e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom)) {
      this.hide(e);
    }
  }
}

